'use client';
import classNames from 'classnames';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import styles from './Table.module.scss';
import { ColumnDefinition, TableOptions, TableProps, Value } from './types';

import Spinner from '../LoadingView/Spinner';
import ArrowDown from '../icons/ArrowDown';

import ErrorFallback from '@/components/ErrorFallback/ErrorFallback';
import Info from '@/components/Info/Info';
import {
  PaginationControls,
  ExpandAllControls,
} from '@/components/Table/PaginationControls';
import renderCell from '@/components/Table/renderCell';

const DEFAULT_OPTIONS: TableOptions = {
  animate: true,
};
/**
 *
 * @param columns must be readonly using 'as const' !!!
 * @param rows
 */
function Table<Columns extends ReadonlyArray<ColumnDefinition>>({
  columns,
  rows,
  onClickRow,
  CustomHeader,
  loading,
  error,
  CustomEmptyState,
  ...props
}: TableProps<Columns>) {
  const options = { ...DEFAULT_OPTIONS, ...props.options };
  const { animate } = options;
  const [isExpanded, setIsExpanded] = useState(false);

  const shouldShowEmptyState = CustomEmptyState && !rows.length;

  const TableError = () => {
    return (
      <tr>
        <td colSpan={columns.length}>
          <ErrorFallback />
        </td>
      </tr>
    );
  };
  return (
    <div
      className={styles.container}
      style={{ ...(rows.length && { height: '100%' }) }}
    >
      <ErrorBoundary fallback={<ErrorFallback />}>
        <table className={styles.table}>
          <thead>
            {CustomHeader}
            <tr className={styles.row}>
              {columns.map((column, idx) => (
                <th key={idx}>
                  <div className={styles.cell}>
                    {column.title}
                    {column.infoOnHover && <Info {...column.infoOnHover} />}
                    {column.sortable && <ArrowDown className={styles.sort} />}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={columns.length} className={styles.loading}>
                  <div className={styles.container}>
                    <Spinner size={48} />
                  </div>
                </td>
              </tr>
            ) : error ? (
              <TableError />
            ) : shouldShowEmptyState ? (
              CustomEmptyState
            ) : (
              <ErrorBoundary fallback={<TableError />}>
                {(options.pagination?.type === 'expand' &&
                !isExpanded &&
                rows.length > options.pagination.props.truncateTo
                  ? rows.slice(0, options.pagination.props.truncateTo)
                  : rows
                ).map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={classNames(styles.row, {
                      [styles.clickable]: onClickRow,
                      [styles.animate]: animate,
                    })}
                    onClick={() => onClickRow?.(row.id)}
                    style={{
                      animationDelay: `${0.03 * rowIndex}s`,
                    }}
                  >
                    {columns.map((column, idx) => {
                      return (
                        <td
                          key={idx}
                          className={classNames({
                            [styles.withFooter]: options.pagination,
                          })}
                          style={{ width: `${100 / columns.length}%` }}
                        >
                          {renderCell(
                            row[column.key as keyof typeof row] as Value,
                            column.type,
                            column.title,
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </ErrorBoundary>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                {options.pagination &&
                options.pagination.type === 'pagination' ? (
                  <PaginationControls {...options.pagination.props} />
                ) : options.pagination?.type === 'expand' ? (
                  <ExpandAllControls
                    expandAll={() => setIsExpanded(true)}
                    canExpand={
                      options.pagination?.type === 'expand' &&
                      !isExpanded &&
                      rows.length > options.pagination.props.truncateTo
                    }
                  />
                ) : (
                  <></>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </ErrorBoundary>
    </div>
  );
}

export default Table;
