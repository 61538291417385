import { ReactNode } from 'react';

import { InfoProps } from '@/components/Info/Info';

export enum ColumnType {
  Text,
  Number,
  Percentage,
  Currency,
  List,
  Custom,
}

export type TableType<T> = { [K in keyof T]: Value };

export type Value = string | number | ReactNode | ReactNode[] | undefined;

type Column = {
  key: string;
  title: string;
  sortable?: boolean;
  infoOnHover?: InfoProps;
  isOptional?: boolean;
};

type NumberColumn = {
  type: ColumnType.Number;
} & Column;

type TextColumn = {
  type: ColumnType.Text;
} & Column;

type PercentageColumn = {
  type: ColumnType.Percentage;
} & Column;

type CurrencyColumn = {
  type: ColumnType.Currency;
} & Column;

type ListColumn = {
  type: ColumnType.List;
} & Column;

type CustomColumn = {
  type: ColumnType.Custom;
} & Column;

export type ColumnDefinition =
  | NumberColumn
  | TextColumn
  | PercentageColumn
  | CurrencyColumn
  | ListColumn
  | CustomColumn;

type OptionalType<
  key extends string,
  T,
  Optional extends boolean | undefined = false,
> = Optional extends true ? { [K in key]?: T } : { [K in key]: T };

type InferDataType<Column extends ColumnDefinition> =
  Column extends NumberColumn
    ? OptionalType<Column['key'], number, Column['isOptional']>
    : Column extends CurrencyColumn
      ? OptionalType<Column['key'], number, Column['isOptional']>
      : Column extends PercentageColumn
        ? OptionalType<Column['key'], number, Column['isOptional']>
        : Column extends TextColumn
          ? OptionalType<Column['key'], string, Column['isOptional']>
          : Column extends ListColumn
            ? OptionalType<Column['key'], ReactNode[], Column['isOptional']>
            : Column extends CustomColumn
              ? OptionalType<Column['key'], ReactNode, Column['isOptional']>
              : never;

export type InferRowType<Columns extends ReadonlyArray<ColumnDefinition>> =
  UnionToIntersection<InferDataType<Columns[number]>> & { id: string };

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

export type PaginationProps = {
  limit: number;
  page: number;
  totalCount: number;
  getNext: () => void;
  getPrevious: () => void;
};

type ExpandProps = { truncateTo: number };
export interface TableOptions {
  animate?: boolean;
  pagination?:
    | { type: 'pagination'; props: PaginationProps }
    | { type: 'expand'; props: ExpandProps };
}
export interface TableProps<Columns extends ReadonlyArray<ColumnDefinition>> {
  columns: Columns;
  rows: InferRowType<Columns>[];
  CustomHeader?: React.ReactElement<HTMLTableRowElement>;
  CustomEmptyState?: React.ReactElement<HTMLTableRowElement>;
  onClickRow?: (id: string) => void;
  options?: TableOptions;
  loading?: boolean;
  error?: string;
}
