import Image from 'next/image';

export default function Spinner({ size }: { size: number }) {
  return (
    <Image
      src="/gifs/spinner.gif"
      alt="Loading..."
      width={size}
      height={size}
    />
  );
}
