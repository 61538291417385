import { FC, useEffect, useState } from 'react';

import styles from './Progress.module.scss';

interface Props {
  percentage: number; // 0 - 1
  width?: number;
  showNumber?: boolean;
}
const Progress: FC<Props> = ({ percentage, width, showNumber = true }) => {
  const [progress, setProgress] = useState(1);
  useEffect(() => {
    setProgress(percentage * 100);
  }, [percentage]);
  return (
    <div className={styles.container}>
      <div className={styles.progress} style={{ width }}>
        <div className={styles.bar} style={{ width: `${progress}%` }} />
      </div>
      {showNumber && <span>{Number(progress.toFixed(2))}%</span>}
    </div>
  );
};

export default Progress;
