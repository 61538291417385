import { ReactNode } from 'react';

import styles from './Modal.module.scss';

const Modal = ({
  hideModal,
  children,
}: {
  hideModal: () => void;
  children: ReactNode;
}) => {
  return (
    <div className={styles.modal} onClick={hideModal}>
      <div
        onClick={(e) => {
          // avoid triggering background click
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
