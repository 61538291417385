import { FC, ReactNode } from 'react';

import styles from './SeeAllModal.module.scss';

import { useModal } from '@/context/modal';

interface Props {
  header: string;
  Content: ReactNode;
}

const SeeAllModal: FC<Props> = ({ header, Content }) => {
  const { hideModal } = useModal();

  return (
    <div className={styles.modal}>
      <div className={styles.header}>{header}</div>
      <div className={styles.content}>{Content}</div>
      <div className={styles.footer}>
        <div className={styles.close} onClick={hideModal}>
          Close
        </div>
      </div>
    </div>
  );
};

export default SeeAllModal;
