import crypto from 'crypto';

import classNames from 'classnames';
import { EventHandler, FC, MouseEvent, ReactNode } from 'react';

import styles from './Badge.module.scss';

export enum Variant {
  GREEN,
  RED,
  ORANGE,
  PURPLE,
  BLUE,
  INDIGO,
  PINK,
  GRAY,
}

const classByVariant: Record<Variant, keyof typeof styles> = {
  [Variant.GREEN]: styles.green,
  [Variant.RED]: styles.red,
  [Variant.ORANGE]: styles.orange,
  [Variant.PURPLE]: styles.purple,
  [Variant.BLUE]: styles.blue,
  [Variant.INDIGO]: styles.indigo,
  [Variant.PINK]: styles.pink,
  [Variant.GRAY]: styles.gray,
};

function stringToNumber(s: string, limit: number) {
  const hash = crypto.createHash('sha256');
  hash.update(s);
  const hexVal = hash.digest('hex');
  const bigIntVal = BigInt('0x' + hexVal);
  return Number(bigIntVal % BigInt(limit));
}

const numOfVariants = Object.keys(classByVariant).filter(
  (variant) => variant !== Variant.GRAY.toString(), // skip gray
).length;

export const generateVariantFromIndex = (idx: number): number => {
  const key = idx % numOfVariants;
  return key;
};

// get variant in a pseudorandom but deterministic way
export const generateVariantFromStringValue = (value: string): number => {
  return generateVariantFromIndex(stringToNumber(value, numOfVariants));
};

interface Props {
  variant: Variant;
  children: ReactNode;
  onClick?: EventHandler<MouseEvent<HTMLDivElement>>;
  maxWidth?: number | string;
  title?: string;
  className?: string;
  largerText?: boolean;
}

const Badge: FC<Props> = ({
  variant,
  children,
  onClick,
  maxWidth = '100%',
  title,
  className,
  largerText,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.badge, classByVariant[variant], {
          [styles.clickable]: onClick,
        })}
        style={{ maxWidth }}
        onClick={onClick}
        title={title}
      >
        <div
          className={classNames(styles.content, className, {
            [styles.largeText]: largerText,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Badge;
