import { ReactNode } from 'react';

import {
  validateComponent,
  validateCurrency,
  validateList,
  validateNumber,
  validatePercentage,
  validateString,
} from './tableValidation';
import { formatLargeNumber } from './utils';

import SeeAllModal from '@/app/_components/SeeAllModal/SeeAllModal';
import Badge, { Variant } from '@/components/Badge/Badge';
import Progress from '@/components/Progress/Progress';
import { ColumnType, Value } from '@/components/Table/types';
import { useModal } from '@/context/modal';

const renderCell = (value: Value, type: ColumnType, columnName: string) => {
  if (value === undefined || value === null) {
    return '';
  }

  try {
    return valueByType[type](value, columnName);
  } catch (error) {
    throw new Error(
      `Table Validation Error: "${(error as Error).message}" \nColumn: "${columnName}"\nType: "${ColumnType[type]}"`,
    );
  }
};

const valueByType: Record<
  ColumnType,
  (value: Value, columnName: string) => ReactNode
> = {
  [ColumnType.Number]: (value) => {
    if (validateNumber(value)) {
      return value;
    }
  },
  [ColumnType.Text]: (value) => {
    if (validateString(value)) {
      return value;
    }
  },
  [ColumnType.Currency]: (value) => {
    if (validateCurrency(value)) {
      return `$${formatLargeNumber(value)}`;
    }
  },
  [ColumnType.Percentage]: (value) => {
    if (validatePercentage(value)) {
      return <Progress percentage={value} />;
    }
  },

  [ColumnType.Custom]: (value) => {
    if (validateComponent(value)) {
      return value;
    }
  },
  [ColumnType.List]: (value, columnName) => {
    if (validateList(value)) {
      return <List items={value} columnName={columnName} />;
    }
  },
};

const List = ({
  items,
  columnName,
}: {
  items: ReactNode[];
  columnName: string;
}) => {
  const displayed = items.slice(0, 3);
  const hidden = items.slice(3);

  const { showModal } = useModal();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 3,
      }}
    >
      {displayed.map((value, idx) => {
        return (
          <div
            style={{
              maxWidth: `${100 / displayed.length}%`,
              overflow: 'hidden',
            }}
            key={idx}
          >
            {value}
          </div>
        );
      })}
      {!!hidden.length && (
        <Badge
          variant={Variant.GRAY}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            showModal(
              <SeeAllModal
                header={`All ${columnName} (${items.length})`}
                Content={<>{items}</>}
              />,
            );
          }}
        >
          +{hidden.length}
        </Badge>
      )}
    </div>
  );
};

export default renderCell;
