import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { JSXElementConstructor, ReactElement, isValidElement } from 'react';

export const validateNumber = (value: any): value is number => {
  if (!isNumber(value)) {
    throw new Error(`Value ${value} is not a number`);
  }

  return true;
};

export const validateString = (value: any): value is string => {
  if (!isString(value)) {
    throw new Error(`Value ${value} is not a string`);
  }

  return true;
};

export const validateCurrency = (value: any): value is number => {
  validateNumber(value);
  // if (value < 0) {
  //   throw new Error(`Value ${value} out of range`);
  // }

  return true;
};

export const validatePercentage = (value: any): value is number => {
  validateNumber(value);

  if (value < 0 || value > 1) {
    throw new Error(`Value ${value} out of range`);
  }

  return true;
};

export const validateComponent = (
  value: any,
): value is ReactElement<unknown, string | JSXElementConstructor<any>> => {
  if (!isValidElement(value)) {
    throw new Error('Not a valid React element');
  }

  return true;
};

export const validateList = (
  value: any,
): value is ReactElement<unknown, string | JSXElementConstructor<any>>[] => {
  if (!isArray(value)) {
    throw new Error('Not an array');
  }

  value.forEach((el) => validateComponent(el));

  return true;
};
