'use client';
import { usePathname } from 'next/navigation';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import Modal from '@/app/_components/Modal/Modal';
import { iProviderProps } from '@/app/_providers/Providers';

interface iModalContext {
  showModal: (modal: ReactNode) => void;
  hideModal: () => void;
}

export const ModalContext = createContext<iModalContext>({
  showModal: () => null,
  hideModal: () => null,
});

export const ModalProvider = ({ children }: iProviderProps) => {
  const location = usePathname();
  const [modal, setModal] = useState<ReactNode | null>(null);

  const locationRef = useRef<string | null>(null);
  const showModal = (content: ReactNode) => {
    setModal(content);
    locationRef.current = location;
  };

  const hideModal = () => {
    setModal(null);
    locationRef.current = null;
  };

  useEffect(() => {
    // close modal on navigation
    if (locationRef.current !== location) {
      hideModal();
    }
  }, [location]);
  return (
    <>
      <ModalContext.Provider value={{ showModal, hideModal }}>
        {modal && <Modal hideModal={hideModal}>{modal}</Modal>}
        {children}
      </ModalContext.Provider>
    </>
  );
};

export const useModal = () => useContext(ModalContext);
