import classNames from 'classnames';
import { FC } from 'react';

import styles from './PaginationControls.module.scss';

import { PaginationProps } from '@/components/Table/types';
export const PaginationControls: FC<PaginationProps> = ({
  page,
  totalCount,
  limit,
  getNext,
  getPrevious,
}) => {
  const numOfPages = Math.ceil(totalCount / limit) || 1;
  const hasPrevious = totalCount > 0 && page > 0;
  const hasNext = totalCount > 0 && page < numOfPages - 1;
  return (
    <div className={styles.pagination}>
      <div
        className={classNames(styles.button, {
          [styles.disabled]: !hasPrevious,
        })}
        onClick={() => hasPrevious && getPrevious()}
      >
        Previous
      </div>
      <div
        className={classNames(styles.button, {
          [styles.disabled]: !hasNext,
        })}
        onClick={() => hasNext && getNext()}
      >
        Next
      </div>

      <div className={styles.key}>
        Page {page + 1} of {numOfPages}
      </div>
    </div>
  );
};

type ExpandAllProps = {
  canExpand: boolean;
  expandAll: () => void;
};

export const ExpandAllControls: FC<ExpandAllProps> = ({
  canExpand,
  expandAll,
}) => {
  return (
    canExpand && (
      <div className={styles.pagination}>
        <div className={classNames(styles.button)} onClick={expandAll}>
          Expand All
        </div>
      </div>
    )
  );
};
