import classNames from 'classnames';
import { FC, useState, useEffect, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';

import styles from './Info.module.scss';

import SvgHelp from '@/icons/components/Help';
export interface InfoProps {
  header?: string;
  children?: ReactNode;
  className?: string;
  position: 'above' | 'below' | 'left' | 'right';
}

type TooltipPosition = {
  top: number;
  left: number;
};
const Info: FC<InfoProps> = ({ children, header, className, position }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] =
    useState<TooltipPosition | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const getTooltipPosition = () => {
    if (ref.current) {
      const { top, left } = ref.current.getBoundingClientRect();
      return {
        top: top + window.scrollY,
        left: left + window.scrollX,
      };
    }
  };

  useEffect(() => {
    getTooltipPosition();
    return () => setShowTooltip(false);
  }, []);

  return (
    <span
      className={styles.target}
      onMouseEnter={() => {
        const pos = getTooltipPosition();
        if (pos) {
          setTooltipPosition(pos);
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => setShowTooltip(false)}
      ref={ref}
    >
      <SvgHelp />
      {showTooltip &&
        tooltipPosition &&
        createPortal(
          <div
            className={classNames(styles.tooltip, className, styles[position])}
            style={{
              top: tooltipPosition.top,
              left: tooltipPosition.left,
            }}
          >
            <div className={styles.header}>{header}</div>
            {children}
            <div className={classNames(styles.arrow, styles[position])}></div>
          </div>,
          document.body,
        )}
    </span>
  );
};

export default Info;
