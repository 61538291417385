export function assertExhaustive(value: never): never {
  throw new Error('Unexpected type ' + value);
}

const COUNT_SUFFIX = ['', 'K', 'M', 'B'];

export const formatLargeNumber = (count: number) => {
  if (count <= 0) {
    return count;
  }

  const numZeros = Math.max(0, Math.log10(count));
  const suffixIndex = Math.min(
    Math.floor(numZeros / 3),
    COUNT_SUFFIX.length - 1,
  );
  const suffixNumZeros = Math.pow(10, suffixIndex * 3);
  const suffix = COUNT_SUFFIX[suffixIndex];

  return (
    (suffixIndex ? Number((count / suffixNumZeros).toFixed(1)) : count) + suffix
  );
};
